@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body {
  font-size: 1.25rem;
  font-family: Raleway;
  text-align: center;
  background-color: aliceblue;
}

.profile {
  left: 0%;
  top: 0%;
  position: absolute;
  width: 120px;
  padding: 2rem;
}

#inputs {
  display: block;
  padding: 2rem;
}

.transactions {
  margin: auto;
  width: 50%;
  border-radius: 1rem;
  outline: auto;
  outline-color: black;
  outline-offset: 1rem;
}

li {
  list-style: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5rem;
}

input {
  font-family: Raleway;
  padding: 2rem;
  font-size: 1rem;
  border-radius: 1rem;
}

.balance {
  width: 40%;
  margin: auto;
  border-radius: 1rem;
  outline: auto;
  outline-color: black;
  outline-offset: 1rem;
}

#content {
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: rem;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.App {
  height: 98vh;
}

#info {
  font-size: 2rem;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#login-page {
  font-size: 2rem;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#login-btn {
  font-size: 1.4rem;
  background-color: #04aa6d;
  border: none;
  border-radius: 1rem;
  color: white;
  padding: 15px 32px;
  text-align: center;
  margin: 4px 2px;
}

#login-btn:hover {
  cursor: pointer;
  filter: invert(1);
}
